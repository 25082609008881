import React from "react";
// import "./CommonButton.css"
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import "./Button.css"

interface ButtonProps {
  btnText: any;
  onClick?: (e: any) => void;
  className?: string;
  disabled?: boolean;
  types?: string;
  id?: any;
  loading?: boolean
}


const Button: React.FC<ButtonProps> = ({ btnText, onClick, className, disabled, types, id, loading }) => {
  const loaderStatus = useSelector((state: any) => state.ui.loaderStatus.spinnerStatus)


  return (
    <button type={types ? "submit" : "button"} id={id} disabled={loaderStatus || disabled} className={`common_button ${className} ${(loaderStatus || disabled || loading) ? "disable_btn" : ""}`} onClick={onClick}> {(loaderStatus || loading) ? <Loader /> : ""} {btnText}</button>
  );
};
Button.defaultProps = {
  disabled: false,
  types: "button",
  className: "btn btn-lg btn-primary btn-block login_btn"
};


export default Button;