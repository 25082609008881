import { IAction } from "../../reducers/userMetadataReducer";
import { ALL_COURSES, SET_USER_METADATA, UNSAVED_CHANGES } from "../../types/UI/userMetadata";

export interface userMetadata {
    username: string,
    role: string,
    token: string,
    isLoggedIn: boolean,
    InstitutionName?: string,
    password: any,
    id?: string
}

export function setUserMetadata(payload: userMetadata): IAction {
    return {
        type: SET_USER_METADATA,
        payload: payload
    }
}
export function setUnsavedChangesData(payload: any): IAction {
    return {
        type: UNSAVED_CHANGES,
        payload: payload
    }
}
export function setAllCoursesData(payload: any): IAction {
    return {
        type: ALL_COURSES,
        payload: payload
    }
}