import { useState, useEffect } from 'react';

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const checkOnlineStatus = async () => {
      try {
        const online = await fetch('https://jsonplaceholder.typicode.com/posts/1')
          .then(response => response.ok)
          .catch(() => false);
        setIsOnline(online);
      } catch (error) {
        setIsOnline(false);
      }
    };

    const handleOnline = () => checkOnlineStatus();
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Initial check
    checkOnlineStatus();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return isOnline;
};

export default useNetworkStatus;
