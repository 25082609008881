import * as type from '../../types/API/types'

export function getTeacherCourses() {
    return {
        type: type.GET_TEACHER_COURSES,
    }
}

export function getStudentsByClass(payload: any) {
    return {
        type: type.GET_STUDENTS_BY_CLASS,
        payload: payload
    }
}

export function deleteUser(payload: any) {
    return {
        type: type.DELETE_USER,
        payload: payload
    }
}


