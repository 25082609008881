import * as React from 'react';
import { Spin } from 'antd';

const Loader: React.FunctionComponent = () => {
  
  return (
    <Spin />
  );
};

export default Loader