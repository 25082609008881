export const classes = "/classes"
export const courses = "/course"
export const institutions = "/institutions"
export const studentCourse = "/student-course"
export const scorecard = "/scorecard"
export const creatorStudio = "/creator-studio"
export const chat = "/chat"









// API path

export const uploadMedia = "Admin/UploadMedia"