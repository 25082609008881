import * as type from "../../types/API/types";

export function getCourses(payload: any) {
  return {
    type: type.GET_COURSES,
    payload: payload,
  };
}

export function getStudentCourses() {
  return {
    type: type.GET_STUDENT_COURSES,
  };
}

export function addCourse(payload: any) {
  return {
    type: type.ADD_COURSE,
    payload: payload,
  };
}

export function addHeirarchy(payload: any) {
  return {
    type: type.ADD_HEIRARCHY,
    payload: payload,
  };
}
export function editHierarchy(payload: any) {
  return {
    type: type.EDIT_HEIRARCHY,
    payload: payload,
  };
}

export function editCourse(payload: any) {
  return {
    type: type.EDIT_COURSE,
    payload: payload,
  };
}
export function editAnalytic(payload: any) {
  return {
    type: type.EDIT_ANALYTIC,
    payload: payload,
  };
}
export function viewSubmission(payload: any) {
  return {
    type: type.VIEW_SUBMISSION,
    payload: payload,
  };
}
export function getSubmission(payload: any) {
  return {
    type: type.GET_SUBMISSION,
    payload: payload,
  };
}

export function deleteCourse(payload: any) {
  return {
    type: type.DELETE_COURSE,
    payload: payload,
  };
}

export function getCoursePages(payload: any) {
  return {
    type: type.GET_COURSE_PAGES,
    payload: payload,
  };
}
export function GetCoursePagesNew(payload: any) {
  return {
    type: type.GET_COURSE_PAGES_NEW,
    payload: payload,
  };
}
export function GetQuestionBank(payload: any) {
  return {
    type: type.GET_QUESTION_BANK,
    payload: payload,
  };
}
export function GetAiCourse(payload: any) {
  return {
    type: type.GET_AI_COURSE,
    payload: payload,
  };
}
export function addTopic(payload: any) {
  return {
    type: type.ADD_TOPIC,
    payload: payload,
  };
}
export function getTopics() {
  return {
    type: type.GET_TOPIC,
  };
}

export function addRegularPage(payload: any) {
  return {
    type: type.ADD_REGULAR_PAGE,
    payload: payload,
  };
}
export function addPages(payload: any) {
  return {
    type: type.ADD_PAGE,
    payload: payload,
  };
}
export function updatePages(payload: any) {
  return {
    type: type.UPDATE_PAGE,
    payload: payload,
  };
}

export function addQuestionPage(payload: any) {
  return {
    type: type.ADD_QUESTION_PAGE,
    payload: payload,
  };
}

export function updateRegularPage(payload: any) {
  return {
    type: type.UPDATE_REGULAR_PAGE,
    payload: payload,
  };
}
export function convertPage(payload: any) {
  return {
    type: type.CONVERT_PAGE,
    payload: payload,
  };
}

export function updateQuestionPage(payload: any) {
  return {
    type: type.UPDATE_QUESTION_PAGE,
    payload: payload,
  };
}

export function getPages(payload: any) {
  return {
    type: type.GET_PAGES,
    payload: payload,
  };
}

export function deletePage(payload: any) {
  return {
    type: type.DELETE_PAGE,
    payload: payload,
  };
}

export function deleteHeirarchy(payload: any) {
  return {
    type: type.DELETE_HEIRARCHY,
    payload: payload,
  };
}

export function getAnalytic(payload: any) {
  return { type: type.GET_ANALYTIC, payload };
}

export function submitQuiz(payload: any) {
  return {
    type: type.SUBMIT_QUIZ,
    payload: payload,
  };
}
export function getUserProfile(payload: any) {
  return {
    type: type.GET_USER_INFO,
    payload: payload,
  };
}
export function updateUserprofile(payload: any){
  return {
    type: type.UPDATE_USER_INFO,
    payload: payload
  }
}
export function GetCourseDetails(payload: any){
  return {
    type: type.GET_COURSE_DETAIL,
    payload: payload
  }
}
export function EnableAnalytic(payload: any){
  return {
    type: type.ENABLE_ANALYTIC,
    payload: payload
  }
}

export function MovePage(payload: any){
  return {
    type: type.MOVE_PAGE,
    payload: payload
  }
}
export function MoveFolder(payload: any){
  return {
    type: type.MOVE_FOLDER,
    payload: payload
  }
}
export function ChangePasswordAction(payload: any){
  return {
    type: type.CHANGE_PASSWORD,
    payload: payload
  }
}
export function updatePublishStatus(payload: any){
  return {
    type: type.UPDATE_PUBLISH_STATUS,
    payload: payload
  }
}
export function getHelp(){
  return {
    type: type.GET_HELP,
  }
}
