import { Autocomplete, Checkbox, TextField } from '@mui/material';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


interface FilterOptionsProps {
    // Define your props here
    data: any[];
    value?: any[];
    onChange: (e: any, val: any[], name: string) => void;
    name: string;
    label: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterOptions: React.FC<FilterOptionsProps> = ({ data, value, onChange, name, label }) => {


    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={data}
            limitTags={2}
            disableCloseOnSelect
            value={value}
            onChange={onChange}
            getOptionLabel={(option: any) => option[name]}
            renderOption={(props, option, { selected }) => {
                const { key, ...optionProps }: any = props;
                return (
                    <li key={key} {...optionProps}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option[name]}
                    </li>
                );
            }}
            style={{ minWidth: 300, flex: 1 }}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={label} />
            )}
        />
    );
};

export default FilterOptions;