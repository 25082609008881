export const GET_USERS_REQUESTED = "GET_USERS_REQUESTED";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const RESET_USER_LOGIN = "RESET_USER_LOGIN";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";

export const REGISTER_USER_BULK = "REGISTER_USER_BULK";
export const REGISTER_USER_BULK_SUCCESS = "REGISTER_USER_BULK_SUCCESS";
export const REGISTER_USER_BULK_FAILED = "REGISTER_USER_BULK_FAILED";

export const GET_DEPARTMENT_CLASSES = "GET_DEPARTMENT_CLASSES";
export const GET_DEPARTMENT_CLASSES_SUCCESS = "GET_DEPARTMENT_CLASSES_SUCCESS";
export const GET_DEPARTMENT_CLASSES_FAILED = "GET_DEPARTMENT_CLASSES_FAILED";

export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_FAILED = "ADD_DEPARTMENT_FAILED";

export const EDIT_DEPARTMENT = "EDIT_DEPARTMENT";
export const EDIT_DEPARTMENT_SUCCESS = "EDIT_DEPARTMENT_SUCCESS";
export const EDIT_DEPARTMENT_FAILED = "EDIT_DEPARTMENT_FAILED";

export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILED = "DELETE_DEPARTMENT_FAILED";

export const ADD_CLASS = "ADD_CLASS";
export const ADD_CLASS_SUCCESS = "ADD_CLASS_SUCCESS";
export const ADD_CLASS_FAILED = "ADD_CLASS_FAILED";

export const EDIT_CONFIG = "EDIT_CONFIG";
export const EDIT_CONFIG_SUCCESS = "EDIT_CONFIG_SUCCESS";
export const EDIT_CONFIG_FAILED = "EDIT_CONFIG_FAILED";

export const EDIT_CLASS = "EDIT_CLASS";
export const EDIT_CLASS_SUCCESS = "EDIT_CLASS_SUCCESS";
export const EDIT_CLASS_FAILED = "EDIT_CLASS_FAILED";

export const GET_TEACHER_COURSES = "GET_TEACHER_COURSES";
export const GET_TEACHER_COURSES_SUCCESS = "GET_TEACHER_COURSES_SUCCESS";
export const GET_TEACHER_COURSES_FAILED = "GET_TEACHER_COURSES_FAILED";

export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAILED = "GET_COURSES_FAILED";

export const GET_STUDENT_COURSES = "GET_STUDENT_COURSES";
export const GET_STUDENT_COURSES_SUCCESS = "GET_STUDENT_COURSES_SUCCESS";
export const GET_STUDENT_COURSES_FAILED = "GET_STUDENT_COURSES_FAILED";

export const ADD_COURSE = "ADD_COURSE";
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_FAILED = "ADD_COURSE_FAILED";

export const EDIT_COURSE = "EDIT_COURSE";
export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS";
export const EDIT_COURSE_FAILED = "EDIT_COURSE_FAILED";

export const GET_COURSE_PAGES = "GET_COURSE_PAGES";
export const GET_COURSE_PAGES_SUCCESS = "GET_COURSE_PAGES_SUCCESS";
export const GET_COURSE_PAGES_FAILED = "GET_COURSE_PAGES_FAILED";

export const GET_COURSE_PAGES_NEW = "GET_COURSE_PAGES_NEW";
export const GET_COURSE_PAGES_NEW_SUCCESS = "GET_COURSE_PAGES_NEW_SUCCESS";
export const GET_COURSE_PAGES_NEW_FAILED = "GET_COURSE_PAGES_NEW_FAILED";

export const GET_QUESTION_BANK = "GET_QUESTION_BANK";
export const GET_QUESTION_BANK_SUCCESS = "GET_QUESTION_BANK_SUCCESS";
export const GET_QUESTION_BANK_FAILED = "GET_QUESTION_BANK_FAILED";

export const GET_AI_COURSE = "GET_AI_COURSE";
export const GET_AI_COURSE_SUCCESS = "GET_AI_COURSE_SUCCESS";
export const GET_AI_COURSE_FAILED = "GET_AI_COURSE_FAILED";

export const GET_TOPIC = "GET_TOPIC";
export const GET_TOPIC_SUCCESS = "GET_TOPIC_SUCCESS";
export const GET_TOPIC_FAILED = "GET_TOPIC_FAILED";

export const ADD_TOPIC = "ADD_TOPIC";
export const ADD_TOPIC_SUCCESS = "ADD_TOPIC_SUCCESS";
export const ADD_TOPIC_FAILED = "ADD_TOPIC_FAILED";

export const DELETE_COURSE = "DELETE_COURSE";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILED = "DELETE_COURSE_FAILED";

export const ADD_HEIRARCHY = "ADD_HEIRARCHY";
export const ADD_HEIRARCHY_SUCCESS = "ADD_HEIRARCHY_SUCCESS";
export const ADD_HEIRARCHY_FAILED = "ADD_HEIRARCHY_FAILED";

export const EDIT_HEIRARCHY = "EDIT_HEIRARCHY";
export const EDIT_HEIRARCHY_SUCCESS = "EDIT_HEIRARCHY_SUCCESS";
export const EDIT_HEIRARCHY_FAILED = "EDIT_HEIRARCHY_FAILED";

export const ADD_REGULAR_PAGE = "ADD_REGULAR_PAGE";
export const ADD_REGULAR_PAGE_SUCCESS = "ADD_REGULAR_PAGE_SUCCESS";
export const ADD_REGULAR_PAGE_FAILED = "ADD_REGULAR_PAGE_FAILED";

export const ADD_PAGE = "ADD_PAGE";
export const ADD_PAGE_SUCCESS = "ADD_PAGE_SUCCESS";
export const ADD_PAGE_FAILED = "ADD_PAGE_FAILED";

export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS";
export const UPDATE_PAGE_FAILED = "UPDATE_PAGE_FAILED";

export const ADD_QUESTION_PAGE = "ADD_QUESTION_PAGE";
export const ADD_QUESTION_PAGE_SUCCESS = "ADD_QUESTION_PAGE_SUCCESS";
export const ADD_QUESTION_PAGE_FAILED = "ADD_QUESTION_PAGE_FAILED";

export const UPDATE_REGULAR_PAGE = "UPDATE_REGULAR_PAGE";
export const UPDATE_REGULAR_PAGE_SUCCESS = "UPDATE_REGULAR_PAGE_SUCCESS";
export const UPDATE_REGULAR_PAGE_FAILED = "UPDATE_REGULAR_PAGE_FAILED";

export const UPDATE_QUESTION_PAGE = "UPDATE_QUESTION_PAGE";
export const UPDATE_QUESTION_PAGE_SUCCESS = "UPDATE_QUESTION_PAGE_SUCCESS";
export const UPDATE_QUESTION_PAGE_FAILED = "UPDATE_QUESTION_PAGE_FAILED";

export const CONVERT_PAGE = "CONVERT_PAGE";
export const CONVERT_PAGE_SUCCESS = "CONVERT_PAGE_SUCCESS";
export const CONVERT_PAGE_FAILED = "CONVERT_PAGE_FAILED";

export const GET_PAGES = "GET_PAGES";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";
export const GET_PAGES_FAILED = "GET_PAGES_FAILED";

export const DELETE_PAGE = "DELETE_PAGE";
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS";
export const DELETE_PAGE_FAILED = "DELETE_PAGE_FAILED";

export const DELETE_HEIRARCHY = "DELETE_HEIRARCHY";
export const DELETE_HEIRARCHY_SUCCESS = "DELETE_HEIRARCHY_SUCCESS";
export const DELETE_HEIRARCHY_FAILED = "DELETE_HEIRARCHY_FAILED";

export const GET_STUDENTS_BY_CLASS = "GET_STUDENTS_BY_CLASS";
export const GET_STUDENTS_BY_CLASS_SUCCESS = "GET_STUDENTS_BY_CLASS_SUCCESS";
export const GET_STUDENTS_BY_CLASS_FAILED = "GET_STUDENTS_BY_CLASS_FAILED";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const GET_ANALYTIC = "GET_ANALYTIC";
export const GET_ANALYTIC_SUCCESS = "GET_ANALYTIC_SUCCESS";
export const GET_ANALYTIC_FAILED = "GET_ANALYTIC_FAILED";

export const EDIT_ANALYTIC = "EDIT_ANALYTICS";
export const EDIT_ANALYTIC_SUCCESS = "EDIT_ANALYTIC_SUCCESS";
export const EDIT_ANALYTIC_FAILED = "EDIT_ANALYTIC_FAILED";

export const GET_SUBMISSION = "GET_SUBMISSION";
export const GET_SUBMISSION_SUCCESS = "GET_SUBMISSION_SUCCESS";
export const GET_SUBMISSION_FAILED = "GET_SUBMISSION_FAILED";

export const VIEW_SUBMISSION = "VIEW_SUBMISSION";
export const VIEW_SUBMISSION_SUCCESS = "VIEW_SUBMISSION_SUCCESS";
export const VIEW_SUBMISSION_FAILED = "VIEW_SUBMISSION_FAILED";

export const SUBMIT_QUIZ = "SUBMIT_QUIZ";
export const SUBMIT_QUIZ_SUCCESS = "SUBMIT_QUIZ_SUCCESS";
export const SUBMIT_QUIZ_FAILED = "SUBMIT_QUIZ_FAILED";

export const DELETE_CLASS = "DELETE_CLASS";
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS";
export const DELETE_CLASS_FAILED = "DELETE_CLASS_FAILED";

export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_FAILED = "UPDATE_USER_INFO_FAILED";

export const GET_COURSE_DETAIL = "GET_COURSE_DETAIL";
export const GET_COURSE_DETAIL_SUCCESS = "GET_COURSE_DETAIL_SUCCESS";
export const GET_COURSE_DETAIL_FAILED = "GET_COURSE_DETAIL_FAILED";

export const ENABLE_ANALYTIC = "ENABLE_ANALYTIC";
export const ENABLE_ANALYTIC_SUCCESS = "ENABLE_ANALYTIC_SUCCESS";
export const ENABLE_ANALYTIC_FAILED = "ENABLE_ANALYTIC_FAILED";

export const MOVE_PAGE = "MOVE_PAGE";
export const MOVE_PAGE_SUCCESS = "MOVE_PAGE_SUCCESS";
export const MOVE_PAGE_FAILED = "MOVE_PAGE_FAILED";

export const MOVE_FOLDER = "MOVE_FOLDER";
export const MOVE_FOLDER_SUCCESS = "MOVE_FOLDER_SUCCESS";
export const MOVE_FOLDER_FAILED = "MOVE_FOLDER_FAILED";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const UPDATE_PUBLISH_STATUS = "UPDATE_PUBLISH_STATUS";
export const UPDATE_PUBLISH_STATUS_SUCCESS = "UPDATE_PUBLISH_STATUS_SUCCESS";
export const UPDATE_PUBLISH_STATUS_FAILED = "UPDATE_PUBLISH_STATUS_FAILED";

export const GET_HELP = "GET_HELP";
export const GET_HELP_SUCCESS = "GET_HELP_SUCCESS";
export const GET_HELP_FAILED = "GET_HELP_FAILED";

export const GET_INSTITUTIONS = "GET_INSTITUTIONS";
export const GET_INSTITUTIONS_SUCCESS = "GET_INSTITUTIONS_SUCCESS";
export const GET_INSTITUTIONS_FAILED = "GET_INSTITUTIONS_FAILED";

export const ADD_INSTITUTION = "ADD_INSTITUTION";
export const ADD_INSTITUTION_SUCCESS = "ADD_INSTITUTION_SUCCESS";
export const ADD_INSTITUTION_FAILED = "ADD_INSTITUTION_FAILED";

export const EDIT_INSTITUTION = "EDIT_INSTITUTION";
export const EDIT_INSTITUTION_SUCCESS = "EDIT_INSTITUTION_SUCCESS";
export const EDIT_INSTITUTION_FAILED = "EDIT_INSTITUTION_FAILED";

export const DELETE_INSTITUTION = "DELETE_INSTITUTION";
export const DELETE_INSTITUTION_SUCCESS = "DELETE_INSTITUTION_SUCCESS";
export const DELETE_INSTITUTION_FAILED = "DELETE_INSTITUTION_FAILED";