import { IAction } from "../../reducers/userMetadataReducer";
import { ACTION_LOADER, LOADER_STATUS, OFFLINE_PAGE_DATA, SPINNER_STATUS } from "../../types/UI/spinner";

export interface appLocation {
    pathname: string
}

export function enableDisableSpinner(payload: boolean ): IAction {
    return  {
        type: SPINNER_STATUS,
        payload: payload
    }
}
export function enableLoader(payload: boolean ): IAction {
    return  {
        type: LOADER_STATUS,
        payload: payload
    }
}
export function actionLoader(payload: any ): IAction {
    return  {
        type: ACTION_LOADER,
        payload: payload
    }
}
export function offlinePageData(payload: any ): IAction {
    return  {
        type: OFFLINE_PAGE_DATA,
        payload: payload
    }
}