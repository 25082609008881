import { IAction } from "../../reducers/userMetadataReducer";
import { APP_BREADCRUMB, APP_PREVIOUS_LOCATION, SCREEN_PROPS, SET_APP_LOCATION } from "../../types/UI/appLocation";

export interface appLocation {
    pathname: string
}
export interface breadCrumb {
    data: any
}

export function setAppLocation(payload: appLocation): IAction {
    return {
        type: SET_APP_LOCATION,
        payload: payload?.pathname ? payload.pathname : payload
    }
}
export function setAppPreviousLocation(payload: appLocation): IAction {
    return {
        type: APP_PREVIOUS_LOCATION,
        payload: payload
    }
}
export function setAppBreadCrumb(payload: breadCrumb): IAction {
    return {
        type: APP_BREADCRUMB,
        payload: payload
    }
}
export function setScreenProps(payload: breadCrumb): IAction {
    return {
        type: SCREEN_PROPS,
        payload: payload
    }
}